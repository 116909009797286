.latestdevelopments_hg {
  background-color: #7dddff;
  height: 0.026042rem;
  width: 13%;
  margin-top: 6%;
  margin-bottom: 3%;
}

.aboutussection {
  margin-bottom: 0.416667rem;
}

.latestdevelopments_main {
  width: 60%;
  margin: 0 auto;
  margin-top: -60%;
}

.latestdevelopments_div {
  color: white;
  margin-top: 2%;
  font-size: 0.09375rem;
}

.latestdevelopments_main_div {
  margin: 4% 0;
  color: white;
  font-size: 0.09375rem;
}

.latestdevelopments_main_content {
  margin-bottom: 6%;
  color: white;
  font-size: 0.09375rem;
}

.latestdevelopments_main_bottom {
  margin-bottom: 30%;
}

.latestdevelopments_img_div {
  margin-top: 8%;
  text-align: center;
}

.latestdevelopments_img_div_img2 {
  margin-left: 8%;
  width: 30%;
}

.latestdevelopments_img_div_img {
  width: 30%;
}

.latestdevelopments_hg_right {
  background-color: #7dddff;
  height: 0.026042rem;
  width: 13%;
  margin-left: 87%;
  margin-top: 6%;
  margin-bottom: 3%;
}

.latestdevelopments_code {
  width: 20%;
  margin: 0 auto;
  margin-bottom: 10%;
}

.rode_div {
  color: #7dddff;
}

.latestdeveimg2 {
  position: relative;
  width: 100%;
  z-index: -1;
}

.aboutus_main_div_ul2 {
  color: white;
  line-height: 300%;
  font-size: 0.09375rem;
  margin-top: 5%;
  list-style: none;
}

.aboutus_main_div_ul2 li {
  position: relative;
  padding-left: 5%;
}

.aboutus_main_div_ul2 li:before {
  content: "";
  display: inline-block;
  width: 0.052083rem;
  height: 0.052083rem;
  background-color: #fff;
  margin-right: 5%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}

.latest_image {
  width: 100%;
  margin-bottom: 6%;
}

.latest_image_tips {
  width: 30% !important;
  display: block;
  margin: 0 auto;
  margin-bottom: 3%;
}

.main_image {
  width: 100%;
}

.latestdevelopments_title {
  color: #93deec;
  font-size: .125rem;
}

.center {
  text-align: center;
}

.latestdevelopments_tips {
  margin-bottom: 1%;
  color: white;
  font-size: 0.09375rem;
}

.htmlcontent img {
  width: 100%;
}

.latestdevelopments16_main {
  width: 750PX !important;
  background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062502944995031.jpg);
}

.latestdevelopments16_main section {
  max-width: 100%;
}

.latestdevelopments16_main #activity-name {
  color: #fff;
}