.joinussection {
  position: relative;
  min-height: 5.625rem;
}

.joinussection_div_div {
  color: #93DEEC;
  font-size: 0.270833rem;
  margin: 0 auto;
  width: 80%;
}

.joinussection_div {
  position: absolute;
  top: 22%;
  right: 16.7%;
  left: 16.7%;
  display: flex;
  bottom: 5%;
}

.joinus-swiper-button-prev {
  width: 0.260417rem;
  height: 0.260417rem;
  background: transparent;
}

.joinus-swiper-button-prev::after {
  content: "" !important;
  width: 100%;
  height: 100%;
  background-image: url(../../images/prev.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.joinus-swiper-button-next {
  width: 0.260417rem;
  height: 0.260417rem;
  background: transparent;
}

.joinus-swiper-button-next::after {
  width: 100%;
  height: 100%;
  content: "" !important;
  background-image: url(../../images/next.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-slide_div_div_div {
  min-width: 0.5rem;
  color: #93DEEC;
  font-size: 0.125rem;
  margin-top: 1%;
}

.swiper-slide_div_div {
  width: 70%;
  margin: 0 auto;
  display: flex;
}

.joinus_main_div_ul2 {
  min-width: 1.614583rem;
  width: 70%;
  text-align: left;
  color: white;
  line-height: 200%;
  font-size: 0.09375rem;
  margin-top: 0;
  list-style: none;
}

.joinus_main_div_ul2 li {
  position: relative;
  padding-left: 5%;
}

.joinus_main_div_ul2 li:before {
  content: "";
  display: inline-block;
  width: 0.052083rem;
  height: 0.052083rem;
  background-color: #fff;
  margin-right: 5%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}

.swiper-slide_ul {
  list-style: none;
  font-size: 0.125rem;
  color: white;
  margin: 0;
  margin-top: 0.020833rem;
}