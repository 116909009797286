.latestdevelopments_hg {
  background-color: #7dddff;
  height: 0.026042rem;
  width: 13%;
  margin-top: 6%;
  margin-bottom: 3%;
}

.latestdevelopments_main {
  width: 60%;
  margin: 0 auto;
  margin-top: -60%;
}

.latestdevelopments_div {
  color: white;
  margin-top: 2%;
  font-size: 0.09375rem;
}

.latestdevelopments_main_div {
  margin: 4% 0;
  color: white;
  font-size: 0.09375rem;
}

.latestdevelopments_main_content {
  margin-bottom: 6%;
  color: white;
  font-size: 0.09375rem;
}

.latestdevelopments_main_bottom {
  margin-bottom: 30%;
}

.latestdevelopments_img_div {
  margin-top: 8%;
  text-align: center;
}

.latestdevelopments_img_div_img2 {
  margin-left: 8%;
  width: 30%;
}

.latestdevelopments_img_div_img {
  width: 30%;
}

.latestdevelopments_hg_right {
  background-color: #7dddff;
  height: 0.026042rem;
  width: 13%;
  margin-left: 87%;
  margin-top: 6%;
  margin-bottom: 3%;
}

.latestdevelopments_code {
  width: 20%;
  margin: 0 auto;
  margin-bottom: 10%;
}

.rode_div {
  color: #7dddff;
}

.latestdeveimg2 {
  width: 100%;
  z-index: 1;
  display: block;
}

.aboutus_main_div_ul2 {
  color: white;
  line-height: 300%;
  font-size: 0.09375rem;
  margin-top: 5%;
  list-style: none;
}

.aboutus_main_div_ul2 li {
  position: relative;
  padding-left: 5%;
}

.aboutus_main_div_ul2 li:before {
  content: "";
  display: inline-block;
  width: 0.052083rem;
  height: 0.052083rem;
  background-color: #fff;
  margin-right: 5%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}

.latest_image {
  width: 100%;
  margin-bottom: 6%;
}

.latest_image_tips {
  width: 100%;
  margin-bottom: 3%;
}

.main_image {
  width: 100%;
}

.latestdevelopments_title {
  color: #93deec;
  font-size: .125rem;
}

.center {
  text-align: center;
}

.latestdevelopments_tips {
  margin-bottom: 1%;
  color: white;
  font-size: 0.09375rem;
}

.flow-image {
  --offset: 0.864583rem;
  width: calc(100% - var(--offset));
  margin-top: 0.260417rem;
}

.flow-image:nth-child(odd) {
  margin-left: var(--offset);
}

.image-group {
  position: relative;
  margin-bottom: 6%;
}

.image-group .image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  transition-duration: 1s;
}

.user-img-text {
  position: relative;
  --width: 0.78125rem;
  margin: 6vw 0;
  border-top: 0.026042rem solid #00397a;
  border-bottom: 0.026042rem solid #00397a;
  padding: 0.260417rem 0.104167rem;
}

.user-img-text::after {
  content: '';
  display: block;
  clear: both;
}

.user-img-text .img-wrapper {
  position: absolute;
  width: var(--width);
  height: var(--width);
  overflow: hidden;
  border-radius: 50%;
  left: 0;
  top: -0.3125rem;
}

.user-img-text .img-wrapper img {
  width: 100%;
}

.user-img-text .content {
  float: left;
  color: white;
  line-height: 2em;
}

.user-img-text .content .stone {
  display: block;
  float: left;
  width: 0.885417rem;
  height: 0.364583rem;
}

.user-img-text:nth-child(even) .img-wrapper {
  left: unset;
  right: 0;
}

.user-img-text:nth-child(even) .content {
  float: right;
}

.user-img-text:nth-child(even) .content .stone {
  float: right;
}