.umerdocotrswiper {
  width: 100%;
  background: transparent;
}

.swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 0.364583rem;
}

.swiper_item_header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  width: 99%;
}

.swiper_item_header img {
  width: 10% !important;
}

.swiper_item_header ul {
  color: white;
  text-align: left;
  list-style: none;
  padding-left: 5%;
  font-weight: 100;
}

.swiper_item_header ul li {
  position: relative;
  font-size: 0.072917rem;
  width: 100%;
  white-space: nowrap;
}

.swiper_item_header ul li:before {
  content: "";
  display: inline-block;
  width: 0.052083rem;
  height: 0.052083rem;
  background-color: #fff;
  margin-right: 5%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -0.104167rem;
}

.swiper_item_header_li {
  font-size: 0.072917rem;
}

.swiper-button-prev {
  width: 0.260417rem;
  height: 0.260417rem;
  background: transparent;
  left: 5%;
}

.swiper-button-prev::after {
  content: "" !important;
  width: 100%;
  height: 100%;
  background-image: url(../../images/prev.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-next {
  width: 0.260417rem;
  height: 0.260417rem;
  background: transparent;
  right: 5%;
}

.swiper-button-next::after {
  width: 100%;
  height: 100%;
  content: "" !important;
  background-image: url(../../images/next.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-slide {
  width: 100%;
  text-align: center;
}

.doctorsection {
  position: relative;
  min-height: 5.625rem;
}

.doctorsectionimg {
  width: 100%;
  z-index: 1;
  display: block;
  position: absolute;
}

.doctorsectiondiv {
  z-index: 2;
  display: flex;
  margin: auto;
  position: relative;
  left: 0;
  width: 68.5%;
  padding-top: 13%;
}

.doctorsectiondiv_main1 {
  width: 35.5%;
}

.main2_div {
  width: 64.5%;
  font-weight: bold;
}

.main2_div p {
  margin-bottom: 7%;
}

.main2_contentimg {
  width: 85%;
}

.main1_div {
  display: flex;
  align-items: center;
  margin-bottom: 14%;
  margin-left: 2%;
}

.section3div3divFris img {
  width: 7.65%;
}

.main_div_app {
  font-size: 0.083333rem;
  margin-left: 5%;
  line-height: 0.3125rem;
  font-size: 0.125rem;
}

.main_div_number {
  color: #A2D5EB;
  font-size: 0.083333rem;
  line-height: 0.3125rem;
}

.main1_div_div {
  color: white;
  font-size: 0.083333rem;
  margin-left: 0.052083rem;
  line-height: 0.3125rem;
}

.main2_div_line {
  width: 100%;
  display: block;
  margin: auto;
  margin-bottom: 2%;
}

.main2_div_div {
  text-align: center;
  color: white;
  padding-bottom: 2%;
  color: #93DEEC;
  font-size: 0.125rem;
}

.main2_div_ul {
  color: white;
  font-size: 0.083333rem;
  list-style: none;
  width: 100%;
  font-weight: 100;
  text-align: left;
}

.main2_div_ul_second {
  width: auto !important;
  padding: 0;
  min-width: 24%;
}

.main2_div_ul_second li {
  padding: 5% 0;
  padding-left: 15% !important;
}

.main2_div_ul li {
  position: relative;
  padding-left: 5%;
  margin-bottom: 2%;
}

.main2_div_ul li:before {
  content: "";
  display: inline-block;
  width: 0.052083rem;
  height: 0.052083rem;
  background-color: #fff;
  margin-right: 5%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}

.main2_div_div2 {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.main2_div_img {
  width: 100%;
}

.main2_div_div2_img {
  display: block;
  margin-left: 10%;
  width: 9%;
}

.main_div_icon {
  width: 13%;
  cursor: pointer;
}

.main_div_icon_active {
  background: #598fa1;
  width: 13%;
  box-shadow: 0 0 0.03125rem #7dddff;
}

#color {
  color: 255;
}

.main2_content_frist,
.main2_content_second {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.main2_content_second {
  padding-top: 7%;
}

.main2_bcpt {
  font-weight: 800;
}

.main2_contentimg_p {
  width: 80%;
}

.main2_div_div2p {
  width: 80%;
  margin: 5% auto;
  color: white;
  font-weight: 100;
  font-size: 0.083333rem;
  letter-spacing: 0.005208rem;
}

.main2_content_frist img:nth-child(1) {
  width: 15%;
}

.main2_content_frist img:nth-child(2) {
  width: 50%;
}

.main2_content_second img:nth-child(1) {
  width: 15%;
}

.main2_content_second img:nth-child(2) {
  width: 28%;
}