.aboutussection {
  position: relative;
  min-height: 5.625rem;
}

.aboutus_main {
  margin-top: -36%;
  margin-left: 18.67%;
  margin-right: 18.67%;
}

.aboutussectionimg {
  width: 100%;
  z-index: 1;
  display: block;
}

.aboutussection_div_div {
  color: #93DEEC;
  font-size: 0.125rem;
}

.aboutussection_div {
  top: 22%;
  position: relative;
  z-index: 3;
  text-align: center;
}

.aboutus_main_div {
  padding-bottom: 16%;
}

.aboutus_main_div_ul {
  list-style: none;
  color: white;
  line-height: 300%;
  font-size: 0.09375rem;
}

.aboutus_main_div_ul2 {
  color: white;
  line-height: 300%;
  font-size: 0.09375rem;
  margin-top: 5%;
  list-style: none;
}

.about_main_top {
  margin-top: 0.052083rem;
}

.about_main_moudle {
  margin-top: 0.145833rem;
}

.about_main_moudle_title {
  font-size: 0.104167rem;
  font-weight: bold;
}

.about_main_moudle_li_title {
  font-size: 0.09375rem;
  font-weight: bold;
  margin-right: 0.052083rem;
}