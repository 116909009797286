.swiper-container {
  overflow: inherit;
}

.ReactCarousel {
  display: flex;
  height: 100%;
}

.contain {
  position: relative;
  transition: all .8s;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  width: 56.5%;
}

.contain .ul {
  height: 100%;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.contain .ul .items {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.ul li.show {
  display: block;
}

.ul li {
  display: none;
}

.ul li img {
  width: 100%;
  height: 100%;
}

.dots {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
}

.dots li {
  flex-shrink: 0;
  float: left;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: 0 0.026042rem;
  border-radius: 100%;
  transition: all .3s;
  background-color: none;
  border: 0.005208rem solid #fff;
  list-style: none;
}

.dots li.active {
  background-color: coral;
  width: 0.072917rem !important;
  height: 0.072917rem !important;
  border: none;
}

.control .left {
  position: absolute;
  top: 50%;
  left: 0.052083rem;
  padding: 0.026042rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0.104167rem;
  cursor: pointer;
}

.control .left:hover {
  background-color: rgba(0,0,0,0.3);
}

.control .right {
  position: absolute;
  top: 50%;
  right: 0.052083rem;
  padding: 0.026042rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0.104167rem;
  cursor: pointer;
}

.control .right:hover {
  background-color: rgba(0,0,0,0.3);
}

.section2 {
  top: -0.005208rem;
}

.section2div {
  flex: 1 1;
  z-index: 3;
  margin-left: 0.286458rem;
  position: relative;
}

.section2divs {
  color: #91E1FAFF;
  padding-top: 0.385417rem;
  font-size: 0.166667rem;
}

.section2divx {
  color: white;
  padding-bottom: 0.666667rem;
  line-height: 0.140625rem;
  padding-top: 0.052083rem;
}

.section2img {
  right: 0;
  bottom: 0;
  width: 60.5%;
  position: absolute;
  display: inline-block;
}

.section2tabimg {
  width: 1.072917rem;
  height: 0.260417rem;
}