.drugssectionimg {
  width: 100%;
}

.drugs_main {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding-top: 1.302083rem;
  display: flex;
}

.drug-item {
  width: 16vw;
  margin: 0.104167rem;
  background-color: #fff;
  border-radius: 0.020833rem;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.drug-item-img {
  width: 100%;
  height: 65%;
  max-width: auto;
  max-height: 65%;
}

.drug-item>.title {
  padding: 0.104167rem;
  text-align: justify;
  font-family: none;
  font-size: 0.083333rem;
  height: 10%;
}

.drug-item>.btn {
  padding: 0.052083rem;
  text-align: justify;
  background: #efefef;
  margin: 0 0.052083rem 0.078125rem 0.052083rem;
  text-align: center;
  font-family: none;
  font-size: 0.083333rem;
  cursor: pointer;
}

.drug-item>.btn>a {
  text-decoration: none;
  color: #000;
}