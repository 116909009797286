.containers {
  width: 100%;
  min-width: 7.5rem;
  font-size: 0.083333rem;
  min-height: 5.625rem;
}

.imgbody {
  width: 100%;
  min-width: 7.5rem;
  z-index: 1;
  display: block;
  background-color: #040f1b;
}

.bodydiv {
  z-index: 3;
  text-align: center;
  top: 25%;
  left: 15.21%;
  position: absolute;
  width: 39.79%;
}

.imgbodyTwo {
  width: 3.979167rem;
  height: 0.78125rem;
  z-index: 1;
}

.sectronp {
  text-align: center;
  font-size: 0.104167rem;
  margin-bottom: 13%;
  color: #80e1ff;
  letter-spacing: 0.020833rem;
}

.sectronpdiv {
  overflow: hidden;
  position: relative;
}

.sectronpimgTwo {
  width: 100%;
  position: absolute;
  bottom: 9.4%;
  z-index: 3;
  left: 0;
}

.secrtonpdimgjqr {
  position: absolute;
  z-index: 3;
  height: 73.23%;
  right: -13%;
  bottom: 10%;
}

.sectronpdivimg {
  width: 100%;
  display: block;
}

.sectronpdivbtn {
  width: 40%;
  margin-top: 0.135417rem;
  cursor: pointer;
  display: inline-block;
}

.section2 {
  position: relative;
}

.section2-main {
  max-width: 10rem;
  position: absolute;
  top: 4.95%;
  left: 15.57%;
  right: 15.57%;
  height: 71.67%;
}

.section2bg {
  width: 100%;
  display: block;
  top: -2%;
}

.section3 {
  position: relative;
  top: -0.010417rem;
}

.section4_ {
  position: relative;
  top: -0.020833rem;
}

.section3_title {
  width: 29.06%;
}

.section3_main {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
}

.section3div1 {
  z-index: 3;
  position: relative;
  text-align: center;
  padding-top: 4.7%;
  padding-bottom: 2%;
}

.section3div2 {
  z-index: 3;
  position: relative;
  text-align: center;
}

.section3_line {
  width: 68.75%;
  left: 15.57%;
  right: 15.57%;
  display: block;
  margin: auto;
}

.section_gif {
  width: 100%;
}

.section3img {
  z-index: 3;
  width: 100%;
  display: block;
}

.section3div3 {
  position: relative;
  z-index: 3;
  display: flex;
  width: 61.25%;
  height: 53%;
  margin: auto;
  margin-top: 6.1%;
  padding: 0 3.75%;
  margin-bottom: 3%;
}

.section3div3_img {
  width: 100%;
  object-fit: cover;
}

.section3div1_img {
  width: 91%;
  object-fit: cover;
}

.section4div4 {
  width: 68.75%;
  padding: 0;
  align-items: center;
  margin-top: 2%;
}

.section3div3Main {
  flex: 1 1;
}

.section3div3Main2 {
  flex: .7 1;
}

.section3div3divFris {
  display: flex;
  align-items: center;
  margin-bottom: 8%;
}

.section3div3divFris img {
  width: 7.65%;
}

.section3div3div {
  display: flex;
}

.section3div3divdiv {
  color: white;
  font-size: 0.083333rem;
  margin-left: 0.234375rem;
  line-height: 0.3125rem;
}

.section3div3divdiv2 {
  color: #A2D5EB;
  font-size: 0.083333rem;
  line-height: 0.3125rem;
}

.section3div3Main_img {
  width: 70%;
  margin-top: 20%;
}

.section3div3divdiv3 {
  color: white;
  font-size: 0.083333rem;
  margin-left: 0.052083rem;
  line-height: 0.3125rem;
}

.section3div4 {
  z-index: 3;
  position: relative;
  width: 68.75%;
  margin: auto;
}

.section3div4img {
  width: 15%;
  cursor: pointer;
  margin: 0 2.5%;
}

.section4ul {
  color: white;
  line-height: 0.208333rem;
  padding-left: 0.104167rem;
}

.section4div3Main {
  width: 65%;
  flex-shrink: 0;
}

.section4div3Main .section4div3img {
  width: 100%;
}

.section4div3Main2imge {
  margin-top: 30%;
  width: 67%;
  display: inline-block;
}

.section_qyfw {
  width: 150%;
  margin-left: -0.3125rem;
  margin-top: 0.244792rem;
}