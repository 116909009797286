@font-face {
  font-family: 'myFamily';
  src: url('../src/Fonts/SourceHanSansCN-Regular.otf');
}

html {
  background: rgb(4,15,27);
}

body {
  background: rgb(4,15,27);
  min-width: 8rem;
  margin: 0;
  font-family: 'myFamily',"SourceHanSansCN-Regular";
  font-size: .1rem;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  /* padding-top: 20px;
  padding-bottom: 10px; */
  min-width: 8.020833rem;
  /* border-bottom: 1px solid white; */
  background: linear-gradient(to bottom, #081621 20%, transparent);
  z-index: 3;
}

.routerul {
  list-style-type: none;
  display: flex;
  align-items: center;
  /* padding: 25px; */
  height: 0.416667rem;
  min-width: 4.166667rem;
  padding-left: 0.635417rem;
  padding-top: 0.104167rem;
  padding-bottom: 0.052083rem;
  margin: 0;
  z-index: 3;
}

.routerli {
  position: relative;
  display: list-item;
  text-align: -webkit-match-parent;
}

.header_line_main {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  height: 0.09375rem;
  bottom: 0;
}

.header_line1 {
  width: 2.208333rem;
  /* border-bottom: 1px solid white; */
  height: 0.015625rem;
  background-image: url(./images/header_lrimg.png);
  background-size: 101% 0.005208rem;
  background-repeat: no-repeat;
  background-position: bottom;
}

.header_line2 {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
}

.header_line2 li {
  padding: 0 0.216146rem;
  /* height: 13px; */
  position: relative;
  /* margin: 0 10px; */
  /* border-bottom: 1px solid white; */
}

.header_line3 {
  flex: 1 1;
  height: 0.015625rem;
  background-image: url(./images/header_lrimg.png);
  background-size: 101% 0.005208rem;
  background-repeat: no-repeat;
  background-position: bottom;
}

.leftrange {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0.09375rem;
  height: 0.09375rem;
}

.leftrange::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0.0625rem;
  height: 0.0625rem;
  box-sizing: border-box;
  border-bottom: 0.005208rem solid white;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transform: rotateZ(-45deg) scale(1.414);
  transform: rotateZ(-45deg) scale(1.414);
  -webkit-animation: slash 5s infinite ease;
  animation: slash 5s infinite ease;
}

.centerline {
  position: absolute;
  top: -0.00625rem;
  border-bottom: 0.010417rem solid white;
  margin: 0 0.057292rem;
  left: 0;
  right: 0;
}

.centerblock {
  position: absolute;
  height: 0.020833rem;
  background: rgb(151,224,252);
  width: 35%;
  bottom: 0;
  margin: auto;
  left: 0.020833rem;
  right: 0;
}

.centerlinesmall {
  margin: 0 0.057292rem !important;
}

.rightrange {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.09375rem;
  height: 0.09375rem;
}

.rightrange::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0.0625rem;
  height: 0.0625rem;
  box-sizing: border-box;
  border-bottom: 0.005208rem solid white;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transform: rotateZ(45deg) scale(1.414);
  transform: rotateZ(45deg) scale(1.414);
  -webkit-animation: slash 5s infinite ease;
  animation: slash 5s infinite ease;
}

.activetab {
  border: 0 !important;
  background-image: url(./images/header_lineimg.png);
  background-repeat: no-repeat;
  background-size: 101% 53.5%;
  background-position: bottom;
}

.noactivetab {
  /* border-bottom:1px solid white */
  background-image: url(./images/header_lrimg.png);
  background-size: 101% 0.005208rem;
  background-repeat: no-repeat;
  background-position: bottom;
}

.liSpan {
  position: absolute;
  width: 0.015625rem;
  top: 0.020833rem;
  bottom: 0.020833rem;
  background: white;
  right: 0;
}

.link {
  padding: 0 0.208333rem;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.005208rem;
  color: white;
}

.img {
  width: 0.651042rem;
  height: 0.208333rem;
  padding-left: 0.921875rem;
  z-index: 3;
}

.imgTwo {
  position: absolute;
  width: 100%;
  min-width: 7.5rem;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(33,67,85,.5);
}

.error_404 {
  color: #02a1b6;
  padding: 18% 17.75%;
  text-align: center;
  font-size: 0.125rem;
}

.loading {
  position: fixed;
  z-index: 4;
  background: lightcoral;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}