.bgbtn_main {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bgbtn_main a {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  text-decoration: none;
  font-size: 0.072917rem;
  color: #060F18;
  text-align: center;
  font-size: .1rem;
}

.bgbtn_main img {
  width: 100%;
}