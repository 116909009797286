.section5 {
  position: relative;
  top: -0.03125rem;
}

.section5div {
  display: flex;
  position: absolute;
  top: 0;
  width: 68.75%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 0.083333rem;
}

.section5divimg {
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100%;
}

.section5divimg img {
  width: 100%;
}

.footer_icon_main {
  display: flex;
  align-items: center;
  width: 110%;
}

.footer_icon {
  width: 4%;
  padding-right: 2%;
}

.section5div2 {
  z-index: 3;
  position: relative;
  padding-top: 10%;
}

.section5div2ul {
  color: white;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.section5div2ul li {
  padding-top: 4%;
}

.section5div3 {
  z-index: 3;
  position: relative;
  color: white;
  margin: auto auto 0;
  flex: 1 1;
  text-align: right;
  margin-bottom: 0.208333rem;
}