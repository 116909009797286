.artificialintelligencesectiondiv {
  z-index: 2;
  display: flex;
  margin: auto;
  position: absolute;
  top: 16%;
  left: 15.75%;
  right: 15.75%;
}

.man2_hyxz {
  color: white;
  margin: 0 auto;
  width: 80%;
  line-height: 0.15625rem;
  font-size: 0.09375rem;
  font-weight: 100;
}

.main2_img {
  margin: 0 auto;
  display: block;
  width: 70%;
  display: block;
  margin-bottom: 10%;
  margin-top: 7%;
}

.man2_hyxz_span {
  color: #7dddff;
  margin-left: 1%;
}

.man2_hyxz_span_right {
  color: #b5b5b6;
  margin-right: 5%;
}

.artificialintelligenceBgimg {
  width: 100%;
  z-index: 1;
  display: block;
}

.man2_dmt {
  color: white;
  margin: 0 auto;
  width: 30%;
  line-height: 0.15625rem;
  font-size: 0.09375rem;
}

.intelligence_tag1_div {
  display: flex;
  margin-top: 5%;
  width: 100%;
}

.intelligence_tag1_div_ul {
  padding: 0;
  margin-left: 2%;
  margin-top: 5%;
  font-weight: normal;
  line-height: 180%;
}

.intelligence_tag1_div_ul li {
  position: relative;
  padding-left: 15%;
}

.intelligence_tag1_div_div {
  color: #7dddff;
  margin-left: 5%;
}

.intelligence_tag1_div_ul_miis {
  padding: 0;
  margin-left: 1%;
  font-weight: normal;
  line-height: 180%;
}

.intelligence_tag1_div_ul_miis li {
  position: relative;
  padding-left: 15%;
}

.intelligence_tag1_div_bt {
  color: white;
  font-weight: 100;
}

.intelligence_tag1_div_yzpfzl {
  color: #7dddff;
}

.div_border_radius_left {
  text-align: center;
  border-radius: 0.104167rem;
  background-color: #7dddff;
  width: 0.9375rem;
  font-size: 0.09375rem;
  margin-bottom: 10%;
}

.div_border_radius_right {
  text-align: center;
  border-radius: 0.104167rem;
  background-color: #b5b5b6;
  width: 0.9375rem;
  font-size: 0.09375rem;
  margin-bottom: 10%;
}

.intelligence_tag1_div_yzpfzl_left {
  color: white;
  text-align: right;
  margin-top: 5%;
}

.intelligence_tag1_div_yzpfzl_right {
  color: #b5b5b6;
  text-align: left;
  margin-top: 5%;
}

.div_vs {
  text-align: center;
  width: 0.78125rem;
}

.vs {
  margin: auto;
  width: 0.364583rem;
  height: 0.229167rem;
  margin-top: 20%;
  display: block;
}

.intelligence_tag1_div_two {
  margin: 0 auto;
  width: 60%;
  margin-top: 3%;
  margin-bottom: 5%;
}

.intelligence_foot_font {
  color: white;
  text-align: left;
  margin-top: 1%;
  margin-bottom: 5%;
}

.Table {
  display: flex;
  margin-top: 1%;
}

.Table_Row_one_left {
  background-color: #7dddff;
  width: 33%;
  text-align: center;
  border-radius: 0.083333rem 0 0 0;
}

.Table_Row_one_center {
  background-color: #7dddff;
  width: 33%;
  text-align: center;
  margin-left: 1%;
  margin-right: 1%;
}

.Table_Row_one_right {
  background-color: #7dddff;
  width: 33%;
  text-align: center;
  border-radius: 0 0.083333rem 0 0;
}

.Table_Row_even {
  background-image: url(../../images/tablebtn.png);
  width: 33%;
  color: white;
  text-align: center;
  font-weight: 100;
}

.Table_Row_even_rigth {
  background-image: url(../../images/tablebtn.png);
  width: 33%;
  color: #7dddff;
  text-align: center;
}

.Table_Row_even_center {
  background-image: url(../../images/tablebtn.png);
  color: white;
  width: 33%;
  text-align: center;
  margin-left: 1%;
  margin-right: 1%;
  font-weight: 100;
}

.Table_Row_odd {
  width: 33%;
  color: white;
  text-align: center;
  font-weight: 100;
}

.Table_Row_odd_right {
  width: 33%;
  color: #7dddff;
  text-align: center;
}

.Table_Row_odd_center {
  color: white;
  width: 33%;
  text-align: center;
  margin-left: 1%;
  margin-right: 1%;
  font-weight: 100;
}

.div_Table {
  margin: 0 auto;
  width: 60%;
}

.multimodalimage {
  width: 100%;
}

.arti_main_div_icon {
  cursor: pointer;
  width: 13%;
}

.arti_main_div_icon_active {
  background: #598fa1;
  box-shadow: 0 0 0.03125rem #7dddff;
}

.main2_div_img {
  width: 100%;
}

.aisia {
  width: 80%;
  margin-top: 0.213542rem;
  margin-left: 10%;
}

.main2_div_title {
  font-weight: 600;
  margin-bottom: 0.104167rem;
}